import type { EntityState } from "@reduxjs/toolkit";
import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import type { Timestamp } from "@somewear/api";
import type {
	GetDefaultSettingsRequest,
	GetDefaultSettingsResponse,
} from "@somewear/api/src/proto/api/workspace_pb";
import type { WorkspaceDefaultSettings } from "@somewear/api/src/proto/workspace_default_settings_pb";
import { createActionSet } from "@somewear/model";
import type { WritableDraft } from "immer/dist/types/types-external";

import type {
	ApplyWorkspaceDefaultSettingsRequest,
	WorkspaceDefaultsRequestSchema,
} from "./workspace.model";
import type { WorkspaceState } from "./workspace.state";

export interface IWorkspaceSettingsState extends WorkspaceDefaultSettings.AsObject {
	id: string;
	applyToDevices: boolean;
	lastUpdated?: Timestamp.AsObject;
}

export const workspaceSettingsActions = {
	applyDefaultSettings: createActionSet<
		ApplyWorkspaceDefaultSettingsRequest,
		GetDefaultSettingsResponse.AsObject
	>("workspace/defaultSettings/apply"),
	updateDefaultSettings: createActionSet<
		WorkspaceDefaultsRequestSchema,
		GetDefaultSettingsResponse.AsObject
	>("workspace/defaultSettings/update"),
	getDefaultSettings: createActionSet<
		GetDefaultSettingsRequest.AsObject,
		GetDefaultSettingsResponse.AsObject
	>("workspace/defaultSettings/get"),
};

const adapter = createEntityAdapter<IWorkspaceSettingsState>({
	selectId: (workspace) => workspace.id,
});

export const { selectAll: selectAllWorkspaceSettings, selectById: selectWorkspaceSettingsById } =
	adapter.getSelectors((state: WorkspaceState) => state.workspaceSettings);

function updateSettings(
	state: WritableDraft<EntityState<IWorkspaceSettingsState>>,
	payload: GetDefaultSettingsResponse.AsObject
): IWorkspaceSettingsState | undefined {
	if (!payload.workspace?.id || !payload.settings) return;

	adapter.upsertOne(state, {
		id: payload.workspace.id,
		applyToDevices: payload.applyToDevicesIsPreferred,
		lastUpdated: payload.lastUpdated,
		...payload.settings,
	});
}

export const workspaceSettingsSlice = createSlice({
	name: "workspaceSettings",
	initialState: adapter.getInitialState(),
	reducers: {
		_: () => {},
	},
	extraReducers: (builder) => {
		builder.addCase(workspaceSettingsActions.getDefaultSettings.fulfilled, (state, action) => {
			updateSettings(state, action.payload.data);
		});
		builder.addCase(
			workspaceSettingsActions.updateDefaultSettings.fulfilled,
			(state, action) => {
				updateSettings(state, action.payload.data);
			}
		);
	},
});
