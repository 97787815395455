import * as TooltipPrimitive from "@radix-ui/react-tooltip"
import * as React from "react"

import { cn } from "../../lib/utils"

const TooltipProvider = TooltipPrimitive.Provider

const Tooltip = (props: TooltipPrimitive.TooltipProps) => (
  <TooltipPrimitive.Root  {...props} delayDuration={props.delayDuration ?? 100} />
)

Tooltip.displayName = TooltipPrimitive.Root.displayName

const TooltipTrigger = TooltipPrimitive.Trigger

const TooltipContent = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content> & { portal?: boolean }
>(({ className, sideOffset = 4, portal = true, ...props }, ref) => {
  const content = (
    <TooltipPrimitive.Content
      ref={ref} 
    sideOffset={sideOffset}
    className={cn(
      "z-50 overflow-hidden rounded-md bg-black px-3 py-1.5 text-xs text-white shadow-md animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 max-w-[300px]",
      className
    )}
    {...props}
  />
  )

  if (portal) {
    return <TooltipPrimitive.Portal>{content}</TooltipPrimitive.Portal>
  }

  return content
})
TooltipContent.displayName = TooltipPrimitive.Content.displayName

export { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger }
